import { Currency, EntityIdDto, Language } from '@nest/common/core/shared';
import { ExchangeRateType } from '@nest/domain/finance/shared';
import { PartyApprovedState } from '@nest/domain/parties/shared';
import { z } from 'zod';

export enum OrderReservationState {
	None = 'None',
	Partially = 'Partially',
	Requested = 'Requested',
	All = 'All'
}

export enum OrderOutboundState {
	None = 'None',
	Partially = 'Partially',
	All = 'All'
}

export enum OrderFulfillmentState {
	Created = 'Created',
	Partially = 'Partially',
	Dispatched = 'Dispatched',
	/** @deprecated TODO: Odstranit po nasazení a aktualizaci dat v databázi */
	Complete = 'Complete'
}
export enum OrderPaymentState {
	Unpaid = 'Unpaid',
	PartiallyPaid = 'PartiallyPaid',
	Paid = 'Paid'
}

export enum SaleOrderAutomationState {
	None = 'None',
	Process = 'Process',
	Failed = 'Failed',
	Completed = 'Completed'
}

export class RunSaleOrderAutomatJobRequestSingletonJob {
	saleOrderId!: number;
	viesRetryCount? = 0;

	singletonKey() {
		return `${this.saleOrderId}/r${this.viesRetryCount}`;
	}
}

export interface CreateSalesChannelDto {
	organization: EntityIdDto;
	name: string;
	enabled: boolean;
	group: any;
	documentKindMask?: string;
	userSelectable?: boolean;
	parent?: EntityIdDto;
	warehouse?: EntityIdDto;
	children: EntityIdDto[];
	saleOrderDocumentType: EntityIdDto;
	party?: EntityIdDto;
	itemCollection?: EntityIdDto;
	brokerMode: boolean;
	exportPricelists: EntityIdDto[];
	pricelistItemsOnly: boolean;
	isDropshipping?: boolean;
	deliveryPricelist?: EntityIdDto;
	usePricelistPrices: boolean;
	defaultSalesman?: EntityIdDto;
	defaultCreditLimit?: number;
	defaultApprovedState?: PartyApprovedState;
	defaultLanguage?: Language;
	exchangeRateType?: ExchangeRateType;
	ediHandler?: string;
	ediHandlerOptions?: any;
	ediFtpAccount?: EntityIdDto;
	testMode: boolean;
	testModeNote?: string;
	scheduledTask?: any;
}

export interface UpdateSalesChannelDto extends CreateSalesChannelDto {
	id: number;
}

export const GetItemPriceQuerySchema = z.object({
	currency: z.nativeEnum(Currency),
	buyerId: z.coerce.number().optional(),
	salesChannelId: z.coerce.number().optional(),
	itemId: z.coerce.number(),
	exchangeRate: z.coerce.number(),
	existingSaleOrderId: z.coerce.number().optional()
});

export type GetItemPriceQuery = z.infer<typeof GetItemPriceQuerySchema>;

export interface DoBatchInvoicesBody {
	orderIds: number[];
}

export interface RecalculateBody {
	order: any;
}

export interface GetDeliveryTypePaymentTypesParam {
	type: string;
}

export interface SaveOrderQuery {
	skipWarnings?: any;
	keepWarnings?: any;
	reserve?: boolean;
}

export interface DoAutomatBody {
	skipWarnings: any;
}

export interface GetPredefinedTextCustomerMessageBody {
	predefinedTextId: number;
}
