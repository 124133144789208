import { defineProcedure, defineRouter } from '@nest/common/rpc/shared';
import { type } from '@nest/utils/shared';
import { z } from 'zod';

export const saleOrderReservationsRouter = defineRouter({
	saleOrdersReservations: {
		reserve: defineProcedure({
			input: z.number(),
			output: type<void>()
		}),
		unreserve: defineProcedure({
			input: z.number(),
			output: type<void>()
		})
	}
});
